<template>
    <zw-sidebar @shown="shown"
                title="KVA"
                :disable-wrapper="true">
        <iframe
            ref="kva-iframe"
            width="100%"
            height="100%"
            style="border:none"
            src=""
        ></iframe>
    </zw-sidebar>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            payload: {},
        }
    },
    methods: {
        shown() {
            this.$refs['kva-iframe'].src = '/project/kva/index/id/' + this.payload
        },
    }
}
</script>